






import Vue from "vue";

export default Vue.extend({
  name: "Feedback",
  components: {},
  data: () => ({}),
  created() {},
  watch: {},
});
